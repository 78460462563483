.section {
  @apply rounded-lg bg-background-primary shadow-lg p-6 mb-6 transition-all duration-500 border border-divider-main;
}

.section:hover {
  @apply shadow-xl;
}

.card {
  @apply rounded-lg shadow-sm bg-background-primary text-text-secondary overflow-hidden;
}

.moduler {
  flex-basis: calc(50% - 10px);
  margin: 5px;
}

.angled-border-right:after,
.angled-border-left:before {
  position: absolute;
  z-index: -1;
  content: "";
  top: 0;
  height: 100%;
  width: 100%;
  background-color: inherit;
  transform: skewX(-15deg);
  border-radius: 8px;
}

.angled-border-right:after {
  right: -8px;
}

.angled-border-left:before {
  left: -8px;
}
