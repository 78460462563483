.custom-container {
  @apply px-3 my-6 w-full max-w-6xl mx-auto;
}

@media (min-width: 640px) {
  .custom-container {
    @apply px-8 my-12;
  }
}

@media (min-width: 768px) {
  .custom-container {
    @apply px-12 my-20;
  }
}
