/* LOADING */

.loader {
  @apply animate-pulse;
  // background: linear-gradient(296deg, #cbd5e0, #e2e8f0);
  // background: linear-gradient(270deg, #e2e8f0, #cbd5e0, #cbd5e0, #e2e8f0);
  background-size: 400% 100%;
  /* animation: Loading 1.3s 8s ease-in-out infinite; */
}

@keyframes Loading {
  0% {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}
