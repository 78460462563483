@import "~antd/dist/antd.less";
// @import "~antd/lib/style/themes/default.less";
@height-base: 36px;
@height-lg: 48px;
@height-sm: 28px;

// 1. COLORS
@blue-base: #3182ce;
@blue-1: #ebf8ff;
@blue-2: #bee3f8;
@blue-3: #90cdf4;
@blue-4: #63b3ed;
@blue-5: #4299e1;
@blue-6: @blue-base;
@blue-7: #2b6cb0;
@blue-8: #2c5282;
@blue-9: #2a4365;
@blue-10: color(~`colorPalette("@{blue-6}", 10) `);

@purple-base: #805ad5;
@purple-1: color(~`colorPalette("@{purple-6}", 1) `);
@purple-2: color(~`colorPalette("@{purple-6}", 2) `);
@purple-3: color(~`colorPalette("@{purple-6}", 3) `);
@purple-4: color(~`colorPalette("@{purple-6}", 4) `);
@purple-5: color(~`colorPalette("@{purple-6}", 5) `);
@purple-6: @purple-base;
@purple-7: color(~`colorPalette("@{purple-6}", 7) `);
@purple-8: color(~`colorPalette("@{purple-6}", 8) `);
@purple-9: color(~`colorPalette("@{purple-6}", 9) `);
@purple-10: color(~`colorPalette("@{purple-6}", 10) `);

@cyan-base: #319795;
@cyan-1: color(~`colorPalette("@{cyan-6}", 1) `);
@cyan-2: color(~`colorPalette("@{cyan-6}", 2) `);
@cyan-3: color(~`colorPalette("@{cyan-6}", 3) `);
@cyan-4: color(~`colorPalette("@{cyan-6}", 4) `);
@cyan-5: color(~`colorPalette("@{cyan-6}", 5) `);
@cyan-6: @cyan-base;
@cyan-7: color(~`colorPalette("@{cyan-6}", 7) `);
@cyan-8: color(~`colorPalette("@{cyan-6}", 8) `);
@cyan-9: color(~`colorPalette("@{cyan-6}", 9) `);
@cyan-10: color(~`colorPalette("@{cyan-6}", 10) `);

@green-base: #38a169;
@green-1: color(~`colorPalette("@{green-6}", 1) `);
@green-2: color(~`colorPalette("@{green-6}", 2) `);
@green-3: color(~`colorPalette("@{green-6}", 3) `);
@green-4: color(~`colorPalette("@{green-6}", 4) `);
@green-5: color(~`colorPalette("@{green-6}", 5) `);
@green-6: @green-base;
@green-7: color(~`colorPalette("@{green-6}", 7) `);
@green-8: color(~`colorPalette("@{green-6}", 8) `);
@green-9: color(~`colorPalette("@{green-6}", 9) `);
@green-10: color(~`colorPalette("@{green-6}", 10) `);

// alias of magenta
@pink-base: #d53f8c;
@pink-1: color(~`colorPalette("@{pink-6}", 1) `);
@pink-2: color(~`colorPalette("@{pink-6}", 2) `);
@pink-3: color(~`colorPalette("@{pink-6}", 3) `);
@pink-4: color(~`colorPalette("@{pink-6}", 4) `);
@pink-5: color(~`colorPalette("@{pink-6}", 5) `);
@pink-6: @pink-base;
@pink-7: color(~`colorPalette("@{pink-6}", 7) `);
@pink-8: color(~`colorPalette("@{pink-6}", 8) `);
@pink-9: color(~`colorPalette("@{pink-6}", 9) `);
@pink-10: color(~`colorPalette("@{pink-6}", 10) `);

@red-base: #e53e3e;
@red-1: #fff5f5;
@red-2: #fed7d7;
@red-3: #feb2b2;
@red-4: #fc8181;
@red-5: #f56565;
@red-6: @red-base;
@red-7: #c53030;
@red-8: #9b2c2c;
@red-9: #9b2c2c;
@red-10: color(~`colorPalette("@{red-6}", 10) `);

@orange-base: #dd6b20;
@orange-1: color(~`colorPalette("@{orange-6}", 1) `);
@orange-2: color(~`colorPalette("@{orange-6}", 2) `);
@orange-3: color(~`colorPalette("@{orange-6}", 3) `);
@orange-4: color(~`colorPalette("@{orange-6}", 4) `);
@orange-5: color(~`colorPalette("@{orange-6}", 5) `);
@orange-6: @orange-base;
@orange-7: color(~`colorPalette("@{orange-6}", 7) `);
@orange-8: color(~`colorPalette("@{orange-6}", 8) `);
@orange-9: color(~`colorPalette("@{orange-6}", 9) `);
@orange-10: color(~`colorPalette("@{orange-6}", 10) `);

@yellow-base: #d69e2e;
@yellow-1: color(~`colorPalette("@{yellow-6}", 1) `);
@yellow-2: color(~`colorPalette("@{yellow-6}", 2) `);
@yellow-3: color(~`colorPalette("@{yellow-6}", 3) `);
@yellow-4: color(~`colorPalette("@{yellow-6}", 4) `);
@yellow-5: color(~`colorPalette("@{yellow-6}", 5) `);
@yellow-6: @yellow-base;
@yellow-7: color(~`colorPalette("@{yellow-6}", 7) `);
@yellow-8: color(~`colorPalette("@{yellow-6}", 8) `);
@yellow-9: color(~`colorPalette("@{yellow-6}", 9) `);
@yellow-10: color(~`colorPalette("@{yellow-6}", 10) `);

@geekblue-base: #5a67d8;
@geekblue-1: color(~`colorPalette("@{geekblue-6}", 1) `);
@geekblue-2: color(~`colorPalette("@{geekblue-6}", 2) `);
@geekblue-3: color(~`colorPalette("@{geekblue-6}", 3) `);
@geekblue-4: color(~`colorPalette("@{geekblue-6}", 4) `);
@geekblue-5: color(~`colorPalette("@{geekblue-6}", 5) `);
@geekblue-6: @geekblue-base;
@geekblue-7: color(~`colorPalette("@{geekblue-6}", 7) `);
@geekblue-8: color(~`colorPalette("@{geekblue-6}", 8) `);
@geekblue-9: color(~`colorPalette("@{geekblue-6}", 9) `);
@geekblue-10: color(~`colorPalette("@{geekblue-6}", 10) `);

@preset-colors: pink, red, orange, yellow, cyan, green, blue, geekblue, purple;

// custom
@gray-1: #f7fafc;
@gray-2: #edf2f7;
@gray-3: #e2e8f0;
@gray-4: #cbd5e0;
@gray-5: #a0aec0;
@gray-6: #718096;
@gray-7: #4a5568;
@gray-8: #2d3748;
@gray-9: #1a202c;

@primary-color: #38a169; // primary color for all components
@link-color: #38a169; // link color
@success-color: #38a169; // success state color
@warning-color: #dd6b20; // warning state color
@error-color: #e53e3e; // error state color

//grays
@heading-color: #1a202c; // heading text color
@text-color: @gray-9; // major text color
@text-color-secondary: @gray-7; // secondary text color

@border-color-base: #e2e8f0; // base border outline a component
@border-color-split: hsv(0, 0, 94%); // split border inside a component
@background-color-light: @gray-1; // background of header and selected item
@background-color-base: @gray-2; // Default grey background color
@input-placeholder-color: @gray-4;

@disabled-color: @gray-4; // disable state color

@icon-color: @gray-6;
@icon-color-hover: @gray-8;

.ant-btn {
  .anticon {
    color: inherit;
    vertical-align: text-top;
  }
}

:focus-visible {
  outline: 0 !important;
}
// shadows

@shadow-color: rgba(0, 0, 0, 0.15);
@shadow-color-inverse: @component-background;
@box-shadow-base: @shadow-2;
@shadow-1-up: 0 -6px 16px -8px rgba(0, 0, 0, 0.08),
  0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
@shadow-1-down: 0 6px 16px -8px rgba(0, 0, 0, 0.08),
  0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
@shadow-1-left: -6px 0 16px -8px rgba(0, 0, 0, 0.08),
  -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
@shadow-1-right: 6px 0 16px -8px rgba(0, 0, 0, 0.08),
  9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
@shadow-2: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05);

@btn-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
@btn-primary-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
@btn-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);

@card-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
  0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);

// other
@btn-text-hover-bg: rgba(0, 0, 0, 0.018);
@divider-color: rgba(0, 0, 0, 6%);
@tooltip-bg: #444;
@tooltip-color: #fff;

.ant-tooltip {
  .ant-tooltip-inner {
    @apply p-2 text-sm;
  }

  img {
    box-shadow: 0 5px 20px -1px rgba(0, 0, 0, 0.1),
      0 8px 12px -2px rgba(0, 0, 0, 0.05);
  }
}

@skeleton-color: rgba(190, 190, 190, 0.2);
@card-skeleton-bg: #cfd8dc;

@image-bg: @gray-4;

// 2. TYPOGRAPHY
@font-size-base: 16px; // major text font size
@line-height-base: 1.2rem;
@border-radius-base: 0.5rem; // major border radius
@border-radius-sm: 3px;
@btn-font-size-lg: @font-size-base;

@font-family: "Inter Var", sans-serif;

@border-width-base: 1px;

// 3. FORMS

// Checkbox + Radio
@checkbox-size: 18px;
@radio-size: 18px;
.ant-checkbox-inner {
  border-radius: @border-radius-sm;
}

.ant-segmented {
  border-radius: 9px;
}

// Outline
// @outline-blur-size: 0;
// @outline-width: 1px;
// @outline-color: @green-7;
// @outline-fade: 50%;

// 4. TABLE

@table-body-sort-bg: @gray-1;
.ant-table-container,
.ant-table-container table > thead > tr:first-child th:first-child,
.ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}

.ant-table-tbody > tr.is-config-line {
  td {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;

    background-color: #f5f3ff38 !important;

    &.highlighted {
      background-color: #f5f3ffd7 !important;
    }

    &:nth-child(1) {
      background-color: #e9d8fd !important;
    }
  }

  &.is-last {
    td {
      border-bottom: 2px solid #e9d8fd;
    }
  }
}

.ant-table-tbody > tr.is-category {
  background-color: #f7fafc !important;

  & > td {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    background-color: #f7fafc !important;
    border-top: 2px solid #f3f4f6 !important;

    & > * {
      font-weight: 400 !important;
      color: #374151 !important;
    }

    &:nth-child(n + 3) {
      & > * {
        opacity: 0;
      }
    }
  }
}

.table-print.ant-table-wrapper {
  table {
    border: 1px solid black;
    border-radius: 0;

    th,
    td {
      border-bottom: 0;
    }

    .ant-table-thead > tr > th {
      background: none !important;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table-summary > tr > td {
      padding: 4px !important;
    }

    .ant-table-tbody {
      .ant-table-row:nth-child(1) {
        & > td {
          border-top: 2px solid black;
        }
      }
    }

    .ant-table-summary {
      tr > td {
        border-top: 2px solid black;
      }
    }

    .ant-table-tbody > tr.table-total {
      &.has-border-top {
        & > td {
          border-top: 3px solid black;
        }
      }

      &.has-border-bottom {
        & > td {
          border-bottom: 3px solid black;
        }
      }
    }

    .ant-table-tbody > tr.empty-cells {
      & > td:not(.first-column) {
        color: transparent;
      }
    }
  }

  &.every-line {
    table .ant-table-tbody {
      .ant-table-row {
        & > td {
          border-top: 1px solid black;
        }
      }
    }
  }

  &:not(.no-alternate) {
    table .ant-table-tbody {
      .ant-table-row:nth-child(3n - 2) {
        & > td {
          border-top: 1px solid black;
        }
      }
    }
  }
}

@colors: {
  1: 0.5rem;
  2: 2.5rem;
  3: 4.5rem;
};

each(@colors, {
  .ant-table-row.ant-table-row-level-@{key} {
   td:nth-child(1)::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 90%;
      left: @value;
      top: 5%;
      background-color: rgb(var(--palette-text-disabled-rgb, 162 174 190));
    }
  }
});

// 5. BUTTON

@btn-font-weight: 400;

// 6. PICKER

@picker-panel-cell-height: 32px;
@picker-panel-cell-width: 46px;

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none;
}

.ant-picker-input input {
  text-align: center;
}

// vertical paddings
@padding-lg: 28px; // containers
@padding-md: 20px; // small containers and buttons
@padding-sm: 16px; // Form controls and items
@padding-xs: 12px; // small items
@padding-xxs: 8px; // more small

@control-padding-horizontal: @padding-xs;
@control-padding-horizontal-sm: @padding-xss;

// 7. SLIDER (fix)

.ant-switch-checked .ant-switch-handle {
  left: 55%;
}

.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: 50%;
}

.ant-switch:not(.ant-switch-checked) {
  background-color: #cbd5e0 !important;
}

// 8. CALENDAR

.ant-picker-calendar.no-sides {
  .ant-picker-cell-inner {
    margin: 0 !important;
    padding: 6px 0 0 !important;
  }

  .ant-picker-calendar-date-value {
    padding: 0 0.6rem !important;
  }
}

// 9. MENU
@menu-bg: none;
@menu-item-height: 55px;
@menu-item-active-bg: none;
@menu-item-vertical-margin: 8px;
@menu-item-color: rgb(113, 128, 150);
@menu-highlight-color: #0f0f0f;

.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  // position: relative;
  // right: unset;
  // margin-left: 10px;
  // transform: unset;
  // transition: unset;
}

// 9. Mobile Time Picker
.ant-picker-time-panel {
  @media (max-width: 768px) {
    width: 250px;
    min-width: auto;
  }
}

.ant-picker-time-panel-column > li {
  @media (max-width: 768px) {
    margin: 0;
    font-size: 25px;
    letter-spacing: 1px;
  }
}

.ant-picker-time-panel-cell-selected {
  @apply font-semibold;
}

.ant-picker-panel-container .ant-picker-footer {
  @media (max-width: 768px) {
    display: none;
  }
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  @media (max-width: 768px) {
    padding: 20px;
    height: auto;
    line-height: 5px !important;
  }
}

// 9. MESSAGES

.ant-message {
  top: calc(var(--status-bar-height) + 8px);
}
