sass-lint:disable-line no-important
  // }

  /* LOADING */

  .loader {
  background: linear-gradient(296deg, #cbd5e0, #e2e8f0);
  background-size: 400% 400%;
  animation: Loading 1.3s ease-in-out alternate infinite;
}

@keyframes Loading {
  0% {
    background-position: 0% 13%;
  }
  50% {
    background-position: 100% 88%;
  }
  100% {
    background-position: 0% 13%;
  }
}

.spinner {
  animation: Spin 0.7s linear infinite;
  transform-origin: center center;
}

@keyframes Spin {
  to {
    transform: rotate(360deg);
  }
}

.animateDash .clippingMask {
  animation: animateDash 2s ease-in-out forwards;
}

@keyframes animateDash {
  from {
    stroke-dashoffset: 965px;
  }
  to {
    stroke-dashoffset: 0px;
  }
}

.gentleSlideLeft {
  // opacity: 0;
  animation-name: gentleSlideLeft;
}

@keyframes gentleSlideLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.gentleSlideRight {
  // opacity: 0;
  animation-name: gentleSlideRight;
}

@keyframes gentleSlideRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.CalendarDay {
  @apply text-primary-dark;
}

.DateInput_input {
  font-size: 18px;
  border-radius: 10px;
  font-weight: normal;
  border: 1px solid #dadada;
  padding: 10px 16px;
  @apply text-text-secondary;
}

.DateInput_input__focused {
  @apply bg-background-secondary border-none;
}

.CalendarDay:focus {
  @apply outline-none border-none;
  /* Fix outline behind */
  z-index: 10;
  position: relative;
}

.CalendarDay__selected {
  @apply bg-primary-dark text-background-primary;
}

.CalendarDay__selected_span {
  @apply bg-background-secondary border-none;
}

.DateRangePickerInput_arrow {
  padding: 0px 8px;
}
